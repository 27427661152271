import { useEffect, useState } from 'react';

export default function Home() {
  const [countdown, setCountdown] = useState(15);

  useEffect(() => {
    const timer = setInterval(() => {
      setCountdown((prevCountdown) => prevCountdown - 1);
    }, 1000);

    // Redirect after 15 seconds
    const redirectTimeout = setTimeout(() => {
      window.location.href = 'https://normanfrederick.com/';
    }, 15000);

    return () => {
      clearInterval(timer);
      clearTimeout(redirectTimeout);
    };
  }, []);

  return (
    <div className="flex justify-center items-center h-screen bg-gray-100">
      <div className="w-[1000px] p-8 bg-white rounded-lg shadow-lg">
        <h1 className="text-2xl font-bold mb-4 text-center">Order Required</h1>
        <p className="text-center text-xl mb-6">The page you’re trying to view requires an active order. Please contact us if you would like to place an order or have questions about an existing order.</p>
        <div className="text-center">
          <a href="https://normanfrederick.com/contact-us/" className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded">
            Contact Us
          </a>
         {countdown <= 0 ? null : <p className='mt-10'>You will be redirected to <a className='text-blue-500' href="https://normanfrederick.com/">normanfrederick.com</a> in {countdown} seconds.</p>} 
        </div>
      </div>
    </div>
  );
}
