
import { useEffect, useState } from "react";
import html2pdf from 'html2pdf.js';
import PasswordForm from "../components/PasswordForm";
import { useParams } from "react-router-dom";



export default function Invoice() {
    const [orderData, setOrderData] = useState([]);
    const [ProductData, setProductData] = useState([]);
    const [paymentData, setPaymentData] = useState([]);
    const [settings, setSettings] = useState([]);
    const [loading, setLoading] = useState(false)
    const [ipAddress, setIPAddress] = useState('');
    const params = useParams();
    const id = params.id;
    const [authenticated, setAuthenticated] = useState(false);

    const idLength = id.length
    const processId = id.slice(20, idLength);

    // Function to decrypt a string back to a number
    const decryptFromString = (encryptedString) => {
        const decryptedNumber = parseInt(atob(encryptedString).split('').map(char => {
            return String.fromCharCode(char.charCodeAt(0) - 97);
        }).join(''), 16);
        return decryptedNumber;
    }

    const decryptId = decryptFromString(processId)
    useEffect(() => {
        fetch('https://api.ipify.org?format=json')
            .then(response => response.json())
            .then(data => {
                setIPAddress(data.ip);
                fetch(`https://matrix.normfred.net/api/orderHistory`, {
                    method: "POST",
                    headers: {
                        "content-type": "application/json"
                    },
                    body: JSON.stringify({
                        OrderNumber: decryptId,
                        ModifiedType: "invoice-view",
                        ModifiedBy: data.ip
                    })

                })
                    .then(res => res.json())
                    .then(data => console.log(data))
            })
    }, [decryptId])
    // console.log(ipAddress)
    // useEffect(() => {
    //     setLoading(true)
    //     // fetch(`${baseURL}/orderHistory`, {
    //     fetch(`http://localhost:4000/api/orderHistory`, {
    //         method: "POST",
    //         headers: {
    //             "content-type": "application/json"
    //         },
    //         body: JSON.stringify({
    //             OrderNumber: decryptId,
    //             ModifiedType: "invoice-view",
    //             ModifiedBy: ipAddress
    //         })

    //     })
    //         .then(res => res.json())
    //         .then(data => console.log(data))
    // }, [decryptId, ipAddress])

    useEffect(() => {
        setLoading(true)
        fetch(`https://matrix.normfred.net/api/order/${decryptId}`)
            .then(res => res.json())
            .then(data => {
                setOrderData(data.data[0])
                setLoading(false)
            })
    }, [decryptId])
    useEffect(() => {
        setLoading(true)
        fetch(`https://matrix.normfred.net/api/setting/order-entry`)
            .then(res => res.json())
            .then(data => {
                setProductData(data?.data)
                setLoading(false)
            })
    }, [])
    useEffect(() => {
        setLoading(true)
        fetch(`https://matrix.normfred.net/api/payment/${decryptId}`)
            .then(res => res.json())
            .then(data => {
                setPaymentData(data?.data)
                setLoading(false)
            })
    }, [decryptId])
    useEffect(() => {
        setLoading(true)
        fetch(`https://matrix.normfred.net/api/setting/system`)
            .then(res => res.json())
            .then(data => {
                setSettings(data?.data[0])
                setLoading(false)
            })
    }, [])


    console.log(settings)
    const handlePaymentClick = () => {
        // Open the payment link in a new window
        window.open('https://swipesimple.com/links/lnk_dda4d798', '_blank');
    };

    if (loading) {
        return <p>loading...</p>
    }

    let rowCounter = 0;
    const { BalanceCost, Concrete, ConcreteCost, CreatedOn, CustomerName, DaysCost, DeliveryDate, DumpSterSize, LaborMisc, LaborMiscCost, MileageCost, MilesOneWay, NumberOfDays, OrderDate, OrderNumber, ProductCost, Shingles, ShinglesCost, TotalCost, TrailerCost, Password, PhoneNumber, PickupDate, DeliveryAddress, BillingAddress, DeliveryContactPhone, DeliveryContact, createdAt, Discount, AdditionalDayCost, ExtraMilesCost } = orderData || {}

    const productName = ProductData.find((item) => item.ID == DumpSterSize);
    console.log(productName)

    console.log(orderData)

    const downloadPDF = () => {
        const element = document.getElementById('invoice');
        const opt = {
            margin: 0.2,
            filename: 'invoice.pdf',
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
        };
        html2pdf().from(element).set(opt).save();
    };


    const handlePasswordSubmit = (passwords) => {
        // Here you can perform password validation, for example, checking against a hardcoded password
        if (passwords === PhoneNumber) {
            setAuthenticated(true);
        } else {
            alert('Incorrect password. Please try again.');
        }
    };

    return (
        <div>
            {!authenticated && (
                <PasswordForm onSubmit={handlePasswordSubmit} />
            )}
            {authenticated && (
                <div className="px-[20px] md:px-[50px] lg:px-[200px] my-[20px] md:my-[50px] lg:my-[100px]">
                    <div className='mt-10' id="invoice">
                        <img src="/1686602090455_nfdump1-Transparent.png" alt="" className='h-10' />
                        <div className='flex flex-col md:flex-row justify-between xl:gap-36 mt-10'>
                            <div>
                                <p className='font-bold'>Norman Frederick Dumpsters, LLC</p>
                                <p>1450 S 25th Street</p>
                                <p>Saginaw, MI 48601</p>
                                <p>989-272-3441</p>
                            </div>
                            <div className='md:flex-1 flex-col md:flex-row'>
                                <div className='flex justify-end text-right'>
                                    <div className='w-56'>
                                        <p className='font-bold'>{CustomerName}</p>
                                        <p>{BillingAddress}</p>
                                        <p>{PhoneNumber}</p>

                                    </div>
                                </div>
                                <br />
                                <div className=' flex justify-end border-b-2 border-dashed border-[#3B82F6] mb-1 text-2xl'>
                                    <p className='mr-2 mb-1'>Invoice {OrderNumber}</p>
                                </div>
                                <div className=' flex justify-end border-b-2 border-dashed border-[#3B82F6] mb-1'>
                                    <p className='mr-2 mb-1'><span className='font-bold'>Order Date</span> {CreatedOn.slice(0, 8)}</p>
                                </div>
                                <div className=' flex justify-end border-b-2 border-dashed border-[#3B82F6] mb-1'>
                                    <p className='mr-2 mb-1'><span className='font-bold'>Delivery Date</span> {DeliveryDate}</p>
                                </div>
                                <div className=' flex justify-end border-b-2 border-dashed border-[#3B82F6]'>
                                    <p className='mr-2 mb-1'><span className='font-bold'>Pickup Date</span> {PickupDate}</p>
                                </div>
                                {
                                    BillingAddress != DeliveryAddress ? <>
                                        <div className=' flex text-sm text-end justify-end border-b-2 border-dashed border-[#3B82F6]'>
                                            <p className='mr-2 mb-1'><span className='font-bold'>Delivery Address: </span>{DeliveryAddress}</p>
                                        </div>
                                        <div className=' text-sm flex text-end justify-end border-b-2 border-dashed border-[#3B82F6]'>
                                            <p className='mr-2 mb-1'><span className='font-bold'>Delivery Contact: </span>{DeliveryContactPhone} {DeliveryContact}</p>
                                        </div>
                                    </> : null
                                }
                            </div>
                        </div>
                        <div className="w-full relative mt-10 overflow-x-auto md:overflow-visible">
                            <table className="w-full border-collapse text-end border border-gray-200 font-bold overflow-x-auto md:overflow-visible">
                                <thead className="bg-[#3b82f6] text-white mr-4 ">
                                    <tr className="mr-3">
                                        <th className="table-th border border-gray-200 px-4 py-4 text-center">QTY</th>
                                        <th className="table-th border border-gray-200 mr-4"><p className="pr-6">Description</p></th>
                                        <th className="table-th border border-gray-200"><p className="pr-6">Price</p></th>
                                        <th className="table-th border border-gray-200"><p className="pr-6">Total</p></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {ProductCost != 0 && (
                                        <tr style={{ backgroundColor: rowCounter++ % 2 === 0 ? '#F3F4F6' : '#FFFFFF' }}>
                                            <td className="table-td border border-gray-200 px-4 py-4 text-center">1</td>
                                            <td className="table-td border border-gray-200 px-4 py-4">{productName?.Description}</td>
                                            <td className="table-td border border-gray-200 px-4 py-4">${ProductCost}</td>
                                            <td className="table-td border border-gray-200 px-4 py-4">${ProductCost}</td>
                                        </tr>
                                    )}
                                    {NumberOfDays > 7 && (
                                        <tr style={{ backgroundColor: rowCounter++ % 2 === 0 ? '#F3F4F6' : '#FFFFFF' }} className="px-[14px] py-[24px]">
                                            <td className="table-td border border-gray-200 px-4 py-4 text-center">{NumberOfDays - 7}</td>
                                            <td className="table-td border border-gray-200 px-4 py-4">ADDITIONAL DAYS</td>
                                            <td className="table-td border border-gray-200 px-4 py-4">${AdditionalDayCost ? AdditionalDayCost : 20}</td>
                                            <td className="table-td border border-gray-200 px-4 py-4">${DaysCost}</td>
                                        </tr>
                                    )}
                                    {MilesOneWay > 25 &&
                                        <tr style={{ backgroundColor: rowCounter++ % 2 === 0 ? '#F3F4F6' : '#FFFFFF' }}>
                                            <td className="table-td border border-gray-200 px-4 py-4 text-center">{MilesOneWay - 25}</td>
                                            <td className="table-td border border-gray-200 px-4 py-4">ADDITIONAL MILEAGE</td>
                                            <td className="table-td border border-gray-200 px-4 py-4">$${ExtraMilesCost ? ExtraMilesCost : 1.15}</td>
                                            <td className="table-td border border-gray-200 px-4 py-4">${MileageCost}</td>
                                        </tr>
                                    }

                                    {ShinglesCost != 0 &&
                                        <tr style={{ backgroundColor: rowCounter++ % 2 === 0 ? '#F3F4F6' : '#FFFFFF' }}>
                                            <td className="table-td border border-gray-200 px-4 py-4 text-center">{Shingles}</td>
                                            <td className="table-td border border-gray-200 px-4 py-4">Shingles</td>
                                            <td className="table-td border border-gray-200 px-4 py-4">$3</td>
                                            <td className="table-td border border-gray-200 px-4 py-4">${ShinglesCost}</td>
                                        </tr>
                                    }
                                    {ConcreteCost != 0 &&
                                        <tr style={{ backgroundColor: rowCounter++ % 2 === 0 ? '#F3F4F6' : '#FFFFFF' }}>
                                            <td className="table-td border border-gray-200 px-4 py-4 text-center">{Concrete}</td>
                                            <td className="table-td border border-gray-200 px-4 py-4">Concrete</td>
                                            <td className="table-td border border-gray-200 px-4 py-4">$25</td>
                                            <td className="table-td border border-gray-200 px-4 py-4">${ConcreteCost}</td>
                                        </tr>
                                    }
                                    {LaborMiscCost != 0 &&
                                        <tr style={{ backgroundColor: rowCounter++ % 2 === 0 ? '#F3F4F6' : '#FFFFFF' }}>
                                            <td className="table-td border border-gray-200 px-4 py-4 text-center">1</td>
                                            <td className="table-td border border-gray-200 px-4 py-4">Labor / Misc Cost</td>
                                            <td className="table-td border border-gray-200 px-4 py-4">${LaborMiscCost}</td>
                                            <td className="table-td border border-gray-200 px-4 py-4">${LaborMiscCost}</td>
                                        </tr>
                                    }
                                    <tr style={{ backgroundColor: rowCounter++ % 2 === 0 ? '#F3F4F6' : '#FFFFFF' }} >
                                        <td className="table-td border border-gray-200 px-4 py-2" colSpan="4"></td>
                                    </tr>
                                    <tr style={{ backgroundColor: rowCounter++ % 2 === 0 ? '#F3F4F6' : '#FFFFFF' }}>
                                        <td className="table-td border border-gray-200 px-4 py-4" colSpan="2"></td>
                                        <td className="table-td text-white border border-gray-200 px-4 py-4" colSpan="2">.</td>
                                    </tr>
                                    {Discount != 0 && Discount ?
                                        <tr>
                                            <td className="table-td" colSpan="2">Discount</td>
                                            <td className="table-td" colSpan="1"></td>
                                            <td className="table-td px-4" colSpan="2">${Discount ? parseFloat(Discount) : 0}</td>
                                        </tr>
                                        : null}
                                    <tr style={{ backgroundColor: rowCounter++ % 2 === 0 ? '#F3F4F6' : '#FFFFFF' }}>
                                        <td className="table-td border border-gray-200 px-4 py-4" colSpan="2" style={{ textAlign: 'end' }}>TOTAL</td>
                                        <td className="table-td border border-gray-200 px-4 py-4" colSpan="1"></td>
                                        <td className="table-td border border-gray-200 px-4 py-4" colSpan="2">${(TotalCost - Discount)}</td>
                                    </tr>
                                    {
                                        paymentData ? paymentData.map((payment) => {
                                            return <tr style={{ backgroundColor: rowCounter++ % 2 === 0 ? '#F3F4F6' : '#FFFFFF' }} className="text-end">
                                                <td className="table-td border border-gray-200 px-4 py-4" colSpan="2" style={{ textAlign: 'end' }}>PAID</td>
                                                <td className="table-td border text-end border-gray-200 px-4 py-4 text-center" colSpan="1">{payment.PaymentType.split("-")[0]}</td>
                                                <td className="table-td border text-end border-gray-200 px-4 py-4 text-center" colSpan="1">${payment.PaymentAmount}</td>
                                            </tr>
                                        }) : null
                                    }
                                    <tr style={{ backgroundColor: rowCounter++ % 2 === 0 ? '#F3F4F6' : '#FFFFFF' }}>
                                        <td className="table-td border border-gray-200 px-4 py-4" colSpan="2" style={{ textAlign: 'end' }}>BALANCE</td>
                                        <td className="table-td border border-gray-200 px-4 py-4" colSpan="1"></td>
                                        <td className="table-td border border-gray-200 px-4 py-4" colSpan="2">${(BalanceCost - Discount)}</td>
                                    </tr>
                                </tbody>
                            </table>
                            {(BalanceCost == 0) && <img src="/paid-stamp.png" alt="" className='w-[200px] absolute top-1/2 left-1/4 ' />}
                        </div>

                    </div>
                    <div className="flex gap-2">
                        <button onClick={downloadPDF} className="bg-[#4472C4] flex items-center gap-3 text-white px-10 py-2 rounded-md hover:bg-[#7da8f0]"><img src="/cloud-arrow-down-solid.svg" className="w-8 h-8" /> <p className="text-xl font-semibold">PDF</p></button>
                        <button onClick={handlePaymentClick} className="bg-[#4472C4] flex gap-3 items-center text-white px-6 py-2 rounded-md hover:bg-[#7da8f0]"><img src="/credit-card-solid.svg" className="w-8 h-8" />  <p className="text-xl font-semibold">PAY NOW</p></button>
                    </div>
                </div>
            )}

        </div>
    );
}
